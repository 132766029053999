import { defineStore } from 'pinia'
import { ref } from 'vue'
import { http } from '@/helpers/http'
import type { TTagHint } from '@/types/tags'

export const useTagsStore = defineStore('tags', () => {
  const hints = ref<TTagHint[]>([])

  let fetched: boolean = false

  async function fetchTags() {
    if (fetched) {
      return
    }
    try {
      const res = await http.post<TTagHint[]>('/tags/hints')
      if (!res) throw new Error('Failed to fetch themes')
      hints.value = res
      fetched = true
    } catch {
      hints.value = []
    }
  }

  return { hints, fetchTags }
})
