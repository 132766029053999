import { http } from '@/helpers/http'
import { Md5 } from 'ts-md5'
import type { TPublicConfigImportModuleMap, TPublicConfigUploadLimits } from '@/types/user'

// for dev purpose / chunk size
import { useStorage } from '@vueuse/core'

export interface ChunkedUploadModelRecordData {
  id: string
  filename: string
  size: number
  chunks_total: number
  last_complete_chunk: number
  last_complete_chunk_checksum: string
  module: string
  percent: number
  user_id: number
}

interface ChunkUploaderClientOptions {
  errorCallback: (error: any) => void
  progressCallback: (percent: number) => void
  successCallback: (data: any) => void
  uploadLimits: TPublicConfigUploadLimits
  importModulesMap: TPublicConfigImportModuleMap
}

type ChunkedUploadResCallback = (model: ChunkedUploadModelRecord | null) => void
type BooleanResCallback = (result: boolean) => void

class ChunkedUploadModelRecord {
  id: string
  filename: string
  size: number
  chunks_total: number
  last_complete_chunk: number
  last_complete_chunk_checksum: string
  module: string
  user_id: number
  percent: number

  constructor(data: ChunkedUploadModelRecordData) {
    this.id = data.id
    this.filename = data.filename
    this.size = data.size
    this.chunks_total = data.chunks_total
    this.last_complete_chunk = data.last_complete_chunk
    this.last_complete_chunk_checksum = data.last_complete_chunk_checksum
    this.module = data.module
    this.user_id = data.user_id
    this.percent = Math.floor((data.last_complete_chunk / data.chunks_total) * 100)
  }
}

export default class ChunkUploaderClient {
  options: ChunkUploaderClientOptions
  errorCallback: (error: any) => void
  progressCallback: (percent: number) => void
  successCallback: (data: any) => void
  uploadLimits: TPublicConfigUploadLimits
  importModulesMap: TPublicConfigImportModuleMap
  chunkSize: number
  uploading: boolean
  currentChunk: number
  chunksTotal: number
  blob: File | null
  module: string | null
  percent: number
  private _cancelUpload: boolean

  constructor(options: ChunkUploaderClientOptions) {
    this.options = options
    this.errorCallback = options.errorCallback
    this.progressCallback = options.progressCallback
    this.successCallback = options.successCallback
    this.uploadLimits = options.uploadLimits
    this.importModulesMap = options.importModulesMap
    this.chunkSize = useStorage('chunkSize', 1024 * 1024 * 2).value
    console.log(this.chunkSize)
    this.uploading = false
    this.currentChunk = 1
    this.chunksTotal = 0
    this.blob = null
    this.module = null
    this.percent = 0
    this._cancelUpload = false
  }

  cancelUpload(): void {
    this._cancelUpload = true
    this.uploading = false
  }

  async checkQueue(module: string, callback: ChunkedUploadResCallback) {
    if (!module || !callback) {
      throw new Error('Missing required parameters!')
    }
    // Assuming $http.get() returns a Promise

    const res = await http.get(`/chunked_upload/check?module=${module}`)

    if (res) {
      const chunkUploadModel = new ChunkedUploadModelRecord(res as ChunkedUploadModelRecordData)
      callback(chunkUploadModel)
    } else {
      callback(null)
    }
  }

  flushQueue(module: string): void {
    if (!module) {
      throw new Error('Missing parameter!')
    }
    http.get(`/chunked_upload/flush_queue?module=${module}`)
  }

  handleUploadError(error: any): void {
    // this.flushQueue(this.module); // Uncomment if you decide to flush the queue on error.
    this.initOrResetState()
    this.errorCallback(error)
  }

  handleUploadComplete(data: any): void {
    this.uploading = false
    this.successCallback(data)
  }

  handleUploadProgress(): void {
    this.currentChunk++
    this.percent = Math.floor((this.currentChunk / this.chunksTotal) * 100)
    this.progressCallback(this.percent)
  }

  startUpload(file: File, module: string): void {
    if (this.uploading) {
      throw new Error('Error, upload in progress!')
    }
    if (!file || !module) {
      throw new Error('Error missing or invalid parameters!')
    }
    if (!file.size) {
      throw new Error('Error this file has invalid size!')
    }
    const maxSizeMb = this.getMaxUploadSize(module)
    const maxSizeBytes = maxSizeMb * 1024 * 1024
    if (file.size > maxSizeBytes) {
      this.errorCallback(`File is too large!, maximum size allowed is ${maxSizeMb}Mb `)
      return
    }
    this.initOrResetState()
    this.blob = file
    this.module = module
    this.chunksTotal = Math.ceil(file.size / this.chunkSize)
    this._cancelUpload = false
    this.uploadChunk()
  }

  getMaxUploadSize(module: string): number {
    const key = this.importModulesMap[module]
    if (!key) {
      throw new Error(`Unknown module ${module}`)
    }
    return this.uploadLimits[key]
  }

  private uploadChunk(): void {
    if (!this.blob || this._cancelUpload) return // Ensure there is a blob to upload and upload hasn't been canceled

    const chunk = this.getChunkFromBlob(this.blob, this.currentChunk)
    console.log(`Uploading chunk ${this.currentChunk}/${this.chunksTotal}`)

    const reader = new FileReader()
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (!e.target?.result) return

      const hash = this.calcHash(e.target.result as ArrayBuffer)
      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = () => this.onReadyStateChanged(xhr)
      xhr.open('POST', '/chunked_upload/upload', true)
      xhr.setRequestHeader('X-Filename', encodeURIComponent(this.blob!.name))
      xhr.setRequestHeader('X-Chunk', this.currentChunk.toString())
      xhr.setRequestHeader('X-ChunksTotal', this.chunksTotal.toString())
      xhr.setRequestHeader('X-Checksum', hash)
      xhr.setRequestHeader('X-Module', this.module!)
      xhr.setRequestHeader('X-Source', this?.module === 'flipbook' ? 'pdf' : this.module!)
      xhr.setRequestHeader('X-Size', this.blob!.size.toString())
      xhr.send(chunk)
    }
    reader.readAsArrayBuffer(chunk)
  }

  private onReadyStateChanged(xhr: XMLHttpRequest): void {
    if (this._cancelUpload) {
      console.log('Upload canceled by the user')
      return
    }
    if (xhr.readyState === 4) {
      try {
        const data = JSON.parse(xhr.responseText)
        if (data.success === true) {
          this.handleUploadProgress()
        } else if (data.error) {
          this.handleUploadError(data.error)
          return
        }
        if (this.currentChunk > this.chunksTotal) {
          this.handleUploadComplete(data)
        } else {
          this.uploadChunk()
        }
      } catch (e) {
        this.handleUploadError('Error uploading file!')
        console.error(xhr.responseText)
        console.error(e)
      }
    }
  }

  resumeFrom(unfinished: ChunkedUploadModelRecord, blob: File): void {
    if (!unfinished || !blob) {
      throw new Error('Invalid parameters!')
    }
    this.module = unfinished.module
    this.blob = blob
    this.currentChunk = unfinished.last_complete_chunk + 1 // Assuming we need to upload the next chunk
    this.chunksTotal = Math.ceil(blob.size / this.chunkSize)
    this.uploadChunk()
  }

  getChunkFromBlob(blob: File, currentChunk: number): Blob {
    const start = (currentChunk - 1) * this.chunkSize
    const end = Math.min(currentChunk * this.chunkSize, blob.size)
    return blob.slice(start, end)
  }

  calcHash(result: ArrayBuffer): string {
    const _md5 = new Md5()
    const byteArray = new Uint8Array(result)
    _md5.appendByteArray(byteArray)
    const hash = _md5.end()?.toString()
    return hash ?? ''
  }

  initOrResetState(): void {
    this.module = null
    this.uploading = false
    this.blob = null
    this.currentChunk = 1
    this.chunksTotal = 0
    this.percent = 0
  }
}
